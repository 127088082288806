<template>
<span>
    <v-layout mb-4 ml-3>
        <v-flex xs10>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Departamentos - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-depts'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-depts :productdept="productdept" :update="true"></form-product-depts>
</span>
</template>

<script>
import FormProductDeptsComponent from "./partial/FormProductDeptsComponent";

export default {
    name: "AddProductDeptsComponent",
    created() {
        this.loadProductDept()
    },
    props: {
        prod_dp_id: {
            require: true
        }
    },
    data() {
        return {
            productdept: {
                prod_dp_id: '',
                prod_dp_nome: '',
                prod_dp_slug: '',
                prod_dp_descricao: '',
                prod_dp_logo: '',
                prod_dp_banner: '',
                prod_dp_status: false,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadProductDept() {
            this.$store.dispatch('loadProductDept', this.prod_dp_id)
                .then(response => this.productdept = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formProductDepts: FormProductDeptsComponent
    }
};
</script>

<style scoped>

</style>
